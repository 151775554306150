import queryString from "query-string";
import {
    addMinutes,
    addHours,
    isValid,
    subSeconds,
    addDays,
    subDays,
    addMonths,
    parseISO,
} from "date-fns";
import { format } from "date-fns-tz";

export const environment = {
    sentryDSN: process.env.REACT_APP_SENTRY_DSN,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || "FAKE_TOKEN", // Using a fake token makes it so mixpanel doesn't run tracking on dev, see https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-371628255
    // Append "+UNRELEASED" to version if this is on master-dashboard.demo
    version: `${process.env.REACT_APP_VERSION}${
        window.location.hostname === "master-dashboard.demo.brdg.ai"
            ? "+UNRELEASED"
            : ""
    }`,
    gitRev: process.env.REACT_APP_GIT_REV,
    gitRevShort: process.env.REACT_APP_GIT_REV?.slice(0, 7),
};

export const getCookieOptions = () => {
    // See https://bugs.chromium.org/p/chromium/issues/detail?id=3699#c12
    // See https://stackoverflow.com/questions/1134290/cookies-on-localhost-with-explicit-domain

    // If the domain is an IP address or localhost
    if (
        document.domain.match(/localhost/)?.[0] ||
        // Regex from https://www.oreilly.com/library/view/regular-expressions-cookbook/9780596802837/ch07s16.html
        document.domain.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)?.[0]
    ) {
        return {};
    } else {
        // Otherwise return the domain object
        // This regex gets the top level domain
        return {
            domain: `.${document.domain.match(/[^.]*\.[^.]*$/)?.[0]}`,
        };
    }
};

export const getAdjustedFrequency = (frequency) => Math.max(frequency, 60 * 10);

export const getDurationFromFrequency = (frequency) =>
    Math.max(24 * getAdjustedFrequency(frequency));

export const paramsSerializer = (params) => {
    params = Object.entries(params).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
    );
    return queryString.stringify(params);
};

export const createDate = (date, hh, mm) => {
    let tmp_date = new Date(date);
    if (!isValid(tmp_date)) throw new Error("Invalid date");
    tmp_date = addHours(tmp_date, hh ?? 0);
    tmp_date = addMinutes(tmp_date, mm ?? 0);
    return tmp_date;
};

export const getStartEndDates = (end_time, duration) => {
    let defaultEnd = createDate(end_time);
    const defaultStart = format(subSeconds(defaultEnd, duration), "yyyy-MM-dd");
    defaultEnd = format(defaultEnd, "yyyy-MM-dd");
    return [defaultStart, defaultEnd];
};

export const defaultDateRange = () => {
    const end_time = new Date().toISOString();
    const duration = 86400 * 119;

    return { end_time, duration };
};

export const closeSidebar = () => {
    document.getElementsByTagName("BODY")[0].classList.add("sidebar-collapse");
};

export const generateTimelineImages = (layer) => {
    const images = [];
    let now = new Date();
    // if ("forage" === layer) {
    //     let current_now = now;
    //     current_now = addDays(current_now, 7);
    //     current_now = format(parseISO(now.toISOString()), "P");
    //     images.push({
    //         name: `Predicted 1 Week`,
    //         capture_time: now.toISOString(),
    //         url: `/map/${layer}.jpg`,
    //         highlight: "",
    //     });
    //     current_now = addMonths(current_now, 1);
    //     current_now = format(parseISO(now.toISOString()), "P");
    //     images.push({
    //         name: `Predicted 1 Month`,
    //         capture_time: now.toISOString(),
    //         url: `/map/${layer}.jpg`,
    //         highlight: "",
    //     });
    //     current_now = addMonths(current_now, 3);
    //     current_now = format(parseISO(now.toISOString()), "P");
    //     images.push({
    //         name: `Predicted 3 Months`,
    //         capture_time: now.toISOString(),
    //         url: `/map/${layer}.jpg`,
    //         highlight: "",
    //     });
    // }
    for (let i = 0; i < 8; i++) {
        const capture_time = now.toISOString();
        const name = format(parseISO(capture_time), "P");
        images.push({
            name: `${name}`,
            capture_time: capture_time,
            url: `/map/${layer}.jpg`,
            highlight: i === 0 && "forage" === layer ? "active" : "",
        });

        if ("forage" === layer && i === 0) {
            let current_now = now;
            current_now = addDays(current_now, 7);
            current_now = format(parseISO(now.toISOString()), "P");
            images.push({
                name: `Predicted 1 Week`,
                capture_time: now.toISOString(),
                url: `/map/${layer}.jpg`,
                highlight: "",
            });
            current_now = addMonths(current_now, 1);
            current_now = format(parseISO(now.toISOString()), "P");
            images.push({
                name: `Predicted 1 Month`,
                capture_time: now.toISOString(),
                url: `/map/${layer}.jpg`,
                highlight: "",
            });
            current_now = addMonths(current_now, 3);
            current_now = format(parseISO(now.toISOString()), "P");
            images.push({
                name: `Predicted 3 Months`,
                capture_time: now.toISOString(),
                url: `/map/${layer}.jpg`,
                highlight: "",
            });
        }

        now = subDays(now, 7);
    }

    return images;
};

export const generateTimeData = (count, yrange, unit="") => {
    var i = 0;
    var series = [];
    const times = [
        "08/09/2021",
        "08/06/2021",
        "08/03/2021",
        "07/31/2021",
        "07/28/2021",
        "07/25/2021",
        "07/22/2021",
        "07/19/2021",
    ];
    while (i < count) {
        var x = times[i % 12];
        var y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
            yrange.min;

        series.push({
            x: x,
            y: `${y} ${unit}`,
        });
        i++;
    }
    return series;
};

export const getRandom = (array) => {
    return array[Math.floor(Math.random() * array.length)];
};

export const getCowStatus = () => {
    return getRandom(["Healthy", "Weak", "Pregnant"]);
};

export const getCowType = () => {
    return getRandom(["Hereford", "Simmental", "Angus", "Longhorn"]);
};

export const getCowFatherMotherName = () => {
    return getRandom([
        "Bovidae Gray",
        "Bovinae",
        "Bos Linnaeus",
        "Taurus",
        "Aurochs",
    ]);
};

export const getCowAge = () => {
    return getRandom(["3", "5", "7", "10"]);
};

export const getCowBirthdate = () => {
    return getRandom(["22/08/2018", "10/02/2013", "02/08/2015", "02/10/2016"]);
};

export const getDummyTableData = (data, length=25) => {
    const dummyData = [];
    for(let i=0; i<length; i++) {
        dummyData.push({...data[i % data.length], id:i+1, index:i+1})
    }
    return dummyData;
}

export const generateFutureTimeData = (count, yrange, unit="") => {
    var i = 0;
    var series = [];
    const times = [
        "08/21/2021",
        "08/18/2021",
        "08/15/2021",
        "08/12/2021",
        "08/09/2021",
        "08/06/2021",
        "08/03/2021",
        "07/31/2021",
    ];
    while (i < count) {
        var x = times[i % 8];
        var y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) +
            yrange.min;

        series.push({
            x: x,
            y: `${y} ${unit}`,
        });
        i++;
    }
    return series;
}
import React, { useReducer, createContext } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { getCookieOptions } from "./utils/helpers";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    token: null,
    zoom: 0,
    layers: ["satellite"],
    compare: [],
    CattleCardOpen:false,
    scroll: {top: 0,left: 0},
};

const AppContext = createContext(initialState);
const { Provider } = AppContext;

const reducer = (state, action) => {
    switch (action.type) {
        case "INIT":
            const token = Cookies.get("accessToken") || null;
            return {
                ...state,
                isInitialized: true,
                // isAuthenticated: token ? true : false,
                // user: token ? jwt_decode(token) : {},
                isAuthenticated: true,
                user: {
                    id: "dummy",
                    name: "Producer",
                    email: "producer@brdg.io",
                },
            };
        case "SCROLL":
            return {
                ...state,
                scroll: action.payload.scroll,
            };
        case "ZOOM":
            return {
                ...state,
                zoom: action.payload.zoom,
            };
        case "COMPARE":
            return {
                ...state,
                compare: action.payload.compare,
            };
        case "LAYERS":
            return {
                ...state,
                layers: action.payload.layers,
            };
        case "CARD1OPEN":
            return {
                ...state,
                Card1Open: action.payload.open,
            };
        case "CARD2OPEN":
            return {
                ...state,
                Card2Open: action.payload.open,
            };
        case "CARD3OPEN":
            return {
                ...state,
                Card3Open: action.payload.open,
            };
        case "LOGIN":
            Cookies.set(
                "accessToken",
                action.payload.token,
                getCookieOptions()
            );

            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
            };
        case "LOGOUT":
            Cookies.remove("accessToken", getCookieOptions());
            return {
                ...initialState,
                isInitialized: true,
            };
        default:
            return state;
    }
};

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AppContext, AppProvider };

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./adminlte.css";
import "./index.css";
import "./cow.css";
import "./pepperdata.css";
import Auth from "containers/Auth";
import { AppProvider } from "store";

library.add(fas);

ReactDOM.render(
    <>
        <React.StrictMode>
            <BrowserRouter>
                <AppProvider>
                    <Auth />
                </AppProvider>
            </BrowserRouter>
        </React.StrictMode>

        <ToastContainer />
    </>,
    document.getElementById("root")
);

import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "containers/Routes/Login/Login/Loadable";
import ResetPassword from "containers/Routes/Login/ResetPassword/Loadable";

function LoginRoute() {
    return (
        <div className={"login-page-container"}>
            <section className="content">
                <div className="container-fluid">
                    <div>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route
                                exact
                                path="/reset-password"
                                component={ResetPassword}
                            />
                        </Switch>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default LoginRoute;

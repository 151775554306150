import React from "react";

import logo from "images/logo.png";

const loadingContainerstyle = {
    margin: "2em auto",
    width: "40px",
    height: "40px",
    position: "relative",
};

function LoadingIndicator() {
    return (
        <div style={loadingContainerstyle}>
            <img src={logo} alt="Loading.." />
        </div>
    );
}

export default LoadingIndicator;

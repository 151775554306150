import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import { useEffectOnce, useTitle } from "react-use";
import { AppContext } from "store";

import LoginRoute from "containers/Routes/Login";
import App from "containers/App/Loadable";

function Auth() {
    let history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const [isLoading, setisLoading] = useState(true);

    useEffectOnce(() => {
        dispatch({
            type: "INIT",
        });
    });

    useEffect(() => {
        if (!state.isInitialized) return;

        if (!state.isAuthenticated) {
            setisLoading(false);
            return history.push("/");
        }

        setisLoading(false);
    }, [
        state.isInitialized,
        state.isAuthenticated,
        state.user,
        history,
        dispatch,
    ]);

    useTitle("BrdgAI");

    if (isLoading) return <LoadingIndicator />;

    if (!state.isAuthenticated) return <LoginRoute />;

    if (state.isAuthenticated) return <App />;
}

export default Auth;
